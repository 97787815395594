import React from 'react'
import classnames from 'classnames'

export default function Hero ({fill, straplineLeft, children}) {

  const heroClasses = classnames({
    'hero': true,
    'hero--fill': fill,
    'hero--strapline-left': straplineLeft
  })

  return (
    <div className={heroClasses}>
      {children}
    </div>
  )
}
